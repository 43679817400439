import {
  ReauthenticationVariant,
  ResourceType,
  SvgIconComponent,
} from 'shared/types'
import { Board, Desk, Car } from 'shared/assets'

export const ResourceTypeIconMapping: Record<ResourceType, SvgIconComponent> = {
  [ResourceType.ConferenceRoom]: Board,
  [ResourceType.Desk]: Desk,
  [ResourceType.ParkingSpot]: Car,
  [ResourceType.Locker]: Desk, //ToDo: Add Locker icon
}

export const mapNumberToReauthenticationVariant = (
  value: number
): ReauthenticationVariant | null => {
  if (Object.values(ReauthenticationVariant).includes(value)) {
    return value as ReauthenticationVariant
  }
  return null
}
