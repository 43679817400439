import { Toolbar, List, DrawerProps as BaseDrawerProps } from '@mui/material'
import { paths } from 'config'
import { ModuleRoute } from 'shared/types'
import DrawerItemLink from './DrawerItemLink'
import SubrouteList from './SubrouteList'
import { Logo, ListContainer, StyledDrawer, Link } from './Drawer.style'

export interface DrawerProps extends Partial<BaseDrawerProps> {
  navItems: ModuleRoute[]
  hidden?: boolean
}

const Drawer = ({ hidden, navItems, ...props }: DrawerProps) => {
  if (hidden) return null
  return (
    <StyledDrawer anchor="left" variant="permanent" {...props}>
      <Link to={paths.home}>
        <Toolbar>
          <Logo />
        </Toolbar>
      </Link>
      <ListContainer>
        <List disablePadding>
          {navItems.map(
            (
              { path, exact, sensitive, strict, linkSettings = {}, subroutes },
              i
            ) =>
              subroutes ? (
                <SubrouteList
                  data={subroutes}
                  {...linkSettings}
                  key={`nav-item-list${i}`}
                />
              ) : (
                <DrawerItemLink
                  key={`nav-item-${i}`}
                  path={path}
                  {...linkSettings}
                />
              )
          )}
        </List>
      </ListContainer>
    </StyledDrawer>
  )
}

export default Drawer
