export enum LoadingStatus {
  Idle = 'idle',
  Pending = 'pending',
  Succeeded = 'succeeded',
  Failed = 'failed',
}

export enum BookingStatus {
  Free = 'free',
  Occupied = 'occupied',
  Cancellable = 'cancellable',
}

export enum ResourceType {
  ConferenceRoom = 'CONFERENCE_ROOM',
  ParkingSpot = 'PARKING_SPOT',
  Desk = 'DESK',
  Locker = 'LOCKER',
}

export enum Modes {
  ResetPassword = 'resetPassword',
  VerifyEmail = 'verifyEmail',
  SignIn = 'signIn',
  ConfirmBooking = 'confirmBooking',
}

export enum ResourceTimeSlotLength {
  FIFTEEN_MINUTES = 'FIFTEEN_MINUTES',
  THIRTY_MINUTES = 'THIRTY_MINUTES',
  ONE_HOUR = 'ONE_HOUR',
  TWO_HOURS = 'TWO_HOURS',
  ONE_DAY = 'ONE_DAY',
}

export enum BookingOffsetUnits {
  DAY = 'D',
  HOUR = 'h',
  MINUTE = 'm',
}

export enum ResourceTimeSlotLengthMinutes {
  FIFTEEN_MINUTES = 15,
  THIRTY_MINUTES = 30,
  ONE_HOUR = 60,
  TWO_HOURS = 120,
  ONE_DAY = 1440,
}

export enum ExternalSystemFieldDataType {
  UInt8 = 'UInt8',
  UInt16 = 'UInt16',
  UInt32 = 'UInt32',
  String = 'String',
  CardFormat = 'CardFormat',
}

export enum CardFormat {
  HID_26_BIT_H10301 = 'HID_26_BIT_H10301',
  HID_35_BIT_CORPORATE_1000 = 'HID_35_BIT_CORPORATE_1000',
  HID_37_BIT_H10304 = 'HID_37_BIT_H10304',
  HID_32_BIT_CUSTOM_WAD3 = 'HID_32_BIT_CUSTOM_WAD3',
}

export enum Recipient {
  AccessProfiles = 'ACCESS_PROFILES',
  Zones = 'ZONES',
  Users = 'USERS',
}

export enum ReactionContent {
  ThumbsUp = 'THUMBS_UP',
  ThumbsDown = 'THUMBS_DOWN',
  ClappingHands = 'CLAPPING_HANDS',
  Heart = 'HEART',
  Neutral = 'NEUTRAL',
}
export enum ReauthenticationStatus {
  SUCCESS = 'success',
}

export enum ResourceBookingViewerConfirmationStatus {
  NotRequired = 'NOT_REQUIRED',
  Done = 'DONE',
  Expired = 'EXPIRED',
  AvailableAfterStartInApp = 'AVAILABLE_AFTER_START_TIME_IN_APP',
  AvailableAfterStartInAppOrViaLock = 'AVAILABLE_AFTER_START_TIME_IN_APP_OR_VIA_LOCK_CONNECTION',
  AvailableAfterStartViaLock = 'AVAILABLE_AFTER_START_TIME_VIA_LOCK_CONNECTION',
  ReadyInApp = 'READY_IN_APP',
  ReadyInAppOrViaLock = 'READY_IN_APP_OR_VIA_LOCK_CONNECTION',
  ReadyViaLock = 'READY_VIA_LOCK_CONNECTION',
  AdminOnly = 'ADMIN_ONLY',
}

export enum ReauthenticationVariant {
  'CHANGE_PASSWORD' = 1,
  'DELETE_ACCOUNT' = 2,
}
