import { useMemo } from 'react'
import { matchPath, useLocation } from 'react-router'
import { useCurrentUser } from 'shared/data'
import { ModuleRoute } from 'shared/types'

export const useCurrentLayoutSettings = (routes: ModuleRoute[]) => {
  const { pathname } = useLocation<{ background?: Location } | null>()
  const currentRoute = routes.find(routeProps =>
    matchPath(pathname, routeProps)
  )
  return currentRoute?.layoutSettings || {}
}

export const useAvailableRoutes = (routes: ModuleRoute[]) => {
  const { data } = useCurrentUser()

  const availableRoutes = useMemo(
    () => (data?.viewer.isAdmin ? routes : routes.filter(r => !r.admin)),
    [data?.viewer.isAdmin, routes]
  )

  return availableRoutes
}
