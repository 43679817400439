import { env } from 'config'
import * as bluebolt from './bluebolt'
import * as globalworth from './globalworth'

const assets = { bluebolt, globalworth }

const selectedAsset = Object.entries(assets).find(
  ([key, value]) => key === env.productFlavor
)

const asset: typeof globalworth | typeof bluebolt = selectedAsset
  ? selectedAsset[1]
  : bluebolt

export const DashboardBooking = asset.DashboardBooking
export const DashboardUsers = asset.DashboardUsers
export const DashboardProfiles = asset.DashboardProfiles
export const DashboardResources = asset.DashboardResources
export const DashboardResourceProfiles = asset.DashboardResourceProfiles
export const DashboardZones = asset.DashboardZones
export const DashboardHistory = asset.DashboardHistory
export const DashboardBuildingFaq = asset.DashboardBuildingFaq
export const ThumbUp = asset.ThumbUp
export const ThumbDown = asset.ThumbDown
export const Neutral = asset.Neutral
export const Heart = asset.Heart
export const HandClap = asset.HandClap
export const Logo = asset.Logo
export const Door = asset.Door
export const Handle = asset.Handle
export const Barrier = asset.Barrier
export const Gate = asset.Gate
export const Car = asset.Car
export const Garage = asset.Garage
export const Lift = asset.Lift
export const Speedgate = asset.Speedgate
export const ActiveBookings = asset.ActiveBookings
export const Book = asset.Book
export const MyPlaces = asset.MyPlaces
export const Calendar = asset.Calendar
export const Filter = asset.Filter
export const CancelBooking = asset.CancelBooking
export const Cancel = asset.Cancel
export const Close = asset.Close
export const MapMarker = asset.MapMarker
export const Board = asset.Board
export const Desk = asset.Desk
export const ConfirmGuySrc = asset.ConfirmGuySrc
export const MarkerSrc = asset.MarkerSrc
export const ResourceUnavailableGuySrc = asset.ResourceUnavailableGuySrc
export const Tutorial = asset.Tutorial

export const User = bluebolt.User

export const Remove = globalworth.Remove
export const Users = globalworth.Users
export const Profiles = globalworth.Profiles
export const Zones = globalworth.Zones
export const History = globalworth.History
export const Booking = globalworth.Booking
export const ResourceProfiles = globalworth.ResourceProfiles
export const Resources = globalworth.Resources
export const BuildingFaq = globalworth.BuildingFaq
export const Logout = globalworth.Logout
export const DeskImageSrc = globalworth.DeskImageSrc
export const ParkingSpotImageSrc = globalworth.ParkingSpotImageSrc
export const ConferenceRoomImageSrc = globalworth.ConferenceRoomImageSrc
export const Show = globalworth.Show
export const Hide = globalworth.Hide
export const Add = globalworth.Add
export const Search = globalworth.Search
export const CloseCircle = globalworth.CloseCircle
export const EditPencil = globalworth.EditPencil
export const DatePickerCalendar = globalworth.DatePickerCalendar
export const ArrowDown = globalworth.ArrowDown
export const ArrowLeft = globalworth.ArrowLeft
export const ArrowRight = globalworth.ArrowRight
export const ArrowUp = globalworth.ArrowUp
export const LogoGW = globalworth.LogoGW
export const Padlock = globalworth.Padlock
export const Mail = globalworth.Mail
