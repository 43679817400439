import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Fab, styled } from '@mui/material'
import { InfoOutlined, HighlightOff, Clear } from '@mui/icons-material'
import { useDialog, useIsSmaller } from 'shared/hooks'
import ConfirmDialog from './ConfirmDialog'
import { isGlobalworth } from 'config'
import { Remove } from 'shared/assets'

interface DataGridActionsProps {
  confirmMessage: string | ReactNode
  onRemoveClick?: () => void
  onShowDetailsClick(): void
  disabledDetails?: boolean
  disabledRemove?: boolean
}

const StyledButton = styled(Button)(({ theme }) => ({
  width: 150,
  [theme.breakpoints.down('md')]: {
    width: 'fit-content',
  },
}))

const MobileRemoveFab = styled(Fab)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  color: theme.palette.primary.main,
  borderColor: theme.palette.primary.main,
  border: `${theme.spacing(0.25)} solid`,
  '&:hover': {
    background: theme.palette.grey[200],
  },
}))

const DataGridActions = ({
  confirmMessage,
  onRemoveClick,
  onShowDetailsClick,
  disabledDetails = false,
  disabledRemove = false,
}: DataGridActionsProps) => {
  const { t } = useTranslation()
  const [isConfirmDialogOpen, toggleConfirmDialog] = useDialog()
  const isMobile = useIsSmaller('md')

  const onDialogConfirm = async () => {
    if (!onRemoveClick) return
    onRemoveClick()
    toggleConfirmDialog()
  }

  const InfoIcon = isGlobalworth ? undefined : <InfoOutlined />
  const RemoveIcon = isGlobalworth ? <Remove /> : <HighlightOff />
  return (
    <>
      {isMobile ? (
        <Fab
          color="primary"
          size="small"
          onClick={onShowDetailsClick}
          disabled={disabledDetails}
        >
          <InfoOutlined />
        </Fab>
      ) : (
        <StyledButton
          color="primary"
          variant="contained"
          startIcon={InfoIcon}
          onClick={onShowDetailsClick}
          disabled={disabledDetails}
        >
          {t('showDetails')}
        </StyledButton>
      )}
      {onRemoveClick && (
        <>
          {isMobile ? (
            <MobileRemoveFab
              color="primary"
              size="small"
              onClick={toggleConfirmDialog}
              disabled={disabledRemove}
            >
              <Clear />
            </MobileRemoveFab>
          ) : (
            <StyledButton
              color="primary"
              variant="outlined"
              startIcon={RemoveIcon}
              onClick={toggleConfirmDialog}
              disabled={disabledRemove}
            >
              {t('remove')}
            </StyledButton>
          )}

          <ConfirmDialog
            open={isConfirmDialogOpen}
            onConfirm={onDialogConfirm}
            onCancel={toggleConfirmDialog}
            message={confirmMessage}
          />
        </>
      )}
    </>
  )
}
export default DataGridActions
