import { DataGridProps } from '@mui/x-data-grid'
import { DataGridOverlay, DataGridToolbar } from 'shared/components'
import { ApolloError } from '@apollo/client'

export const useDataGrid = (
  error: ApolloError | undefined,
  pageSize: number,
  onPageSizeChange: (pageSize: number) => void,
  rowsPerPageOptions: number[],
  rowCount: number,
  page: number,
  onPageChange: (page: number) => void,
  query: string,
  setQuery: (query: string) => void,
  isLoading: boolean
) => {
  const dataGridProps: Partial<DataGridProps> = {
    error: error?.message,
    pageSize,
    onPageSizeChange,
    hideFooterSelectedRowCount: true,
    pagination: true,
    paginationMode: 'server',
    rowsPerPageOptions,
    rowCount,
    page,
    onPageChange,
    components: {
      Toolbar: () => (
        <DataGridToolbar
          searchQuery={query}
          onSearch={setQuery}
          isLoading={isLoading}
        />
      ),
      LoadingOverlay: () => <DataGridOverlay />,
    },
  }

  return { dataGridProps }
}
