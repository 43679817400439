import {
  getAuth,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  Auth,
  ActionCodeSettings,
  sendEmailVerification,
  confirmPasswordReset,
  sendPasswordResetEmail,
  verifyPasswordResetCode,
  applyActionCode,
  fetchSignInMethodsForEmail,
  sendSignInLinkToEmail,
  signInWithEmailLink,
  isSignInWithEmailLink,
  updatePassword,
  User,
  reauthenticateWithCredential,
  EmailAuthProvider,
  getIdToken,
  deleteUser,
} from '@firebase/auth'
import {
  CHANGE_PASSWORD_QUERY_PARAM,
  DELETE_ACCOUNT_QUERY_PARAM,
  env,
} from 'config'
import { NextFn } from 'shared/types'
import Firebase from './Firebase'
import { AppCheck, AppCheckTokenResult, getToken } from '@firebase/app-check'
import { FirebaseApp } from '@firebase/app'

class FirebaseAuth {
  private instance = Firebase.getInstance()

  private app: FirebaseApp = this.instance.app

  private appCheck: AppCheck | null = this.instance.appCheck

  private auth: Auth = getAuth(this.app)

  confirmPasswordReset = async (code: string, password: string) => {
    await confirmPasswordReset(this.auth, code, password)
  }

  verifyPasswordResetCode = async (code: string) => {
    await verifyPasswordResetCode(this.auth, code)
  }

  sendPasswordResetEmail = async (email: string) => {
    const actionCodeSettings: ActionCodeSettings = {
      url: env.appUrl,
    }
    await sendPasswordResetEmail(this.auth, email, actionCodeSettings)
  }

  activateAccount = async (code: string) => {
    return applyActionCode(this.auth, code)
  }

  signIn = async (email: string, password: string) => {
    const response = await signInWithEmailAndPassword(
      this.auth,
      email,
      password
    )
    if (!response.user.emailVerified) {
      const actionCodeSettings: ActionCodeSettings = {
        url: env.appUrl,
        android: {
          packageName: env.appAndroidPackageName,
        },
        iOS: {
          bundleId: env.appIosBundleId,
        },
        handleCodeInApp: true,
      }
      await sendEmailVerification(response.user, actionCodeSettings)
      this.auth.signOut()
      // eslint-disable-next-line no-throw-literal
      throw { code: 'account-not-activated' }
    }
  }

  signInWithEmailLink = async (email: string, emailLink: string) =>
    await signInWithEmailLink(this.auth, email, emailLink)

  signOut = () => signOut(this.auth)

  onAuthStateChanged = (next: NextFn<User | null>) =>
    onAuthStateChanged(this.auth, async user => next(user))

  fetchSignInMethodsForEmail = async (email: string) =>
    await fetchSignInMethodsForEmail(this.auth, email)

  sendSignInLinkToEmail = async (email: string) => {
    const actionCodeSettings: ActionCodeSettings = {
      url: env.appUrl,
      handleCodeInApp: true,
    }
    return await sendSignInLinkToEmail(this.auth, email, actionCodeSettings)
  }

  sendSignInLinkToEmailBeforPasswordChange = async (email: string) => {
    const actionCodeSettings: ActionCodeSettings = {
      url: `${env.appUrl}?${CHANGE_PASSWORD_QUERY_PARAM}=true`,
      handleCodeInApp: true,
    }
    return await sendSignInLinkToEmail(this.auth, email, actionCodeSettings)
  }

  sendSignInLinkToEmailBeforDeleteAccount = async (email: string) => {
    const actionCodeSettings: ActionCodeSettings = {
      url: `${env.appUrl}?${DELETE_ACCOUNT_QUERY_PARAM}=true`,
      handleCodeInApp: true,
    }
    return await sendSignInLinkToEmail(this.auth, email, actionCodeSettings)
  }

  isSignInWithEmailLink = (emailLink: string) =>
    isSignInWithEmailLink(this.auth, emailLink)

  getAppCheckToken = (): Promise<AppCheckTokenResult> | undefined => {
    if (this.appCheck === null) return
    return getToken(this.appCheck)
  }
  reloadUser = async () => {
    await this.auth.currentUser?.reload()
  }

  updatePassword = async (newPassword: string) => {
    if (!this.auth.currentUser) return
    return updatePassword(this.auth.currentUser, newPassword)
  }

  reauthenticateWithPasswordCredential = async (
    email: string,
    password: string
  ) => {
    if (!this.auth.currentUser) return
    const credential = EmailAuthProvider.credential(email, password)
    return reauthenticateWithCredential(this.auth.currentUser, credential)
  }

  reauthenticateWithEmailLinkCredential = async (
    email: string,
    emailLink: string
  ) => {
    if (!this.auth.currentUser) return
    const credential = EmailAuthProvider.credentialWithLink(email, emailLink)
    return reauthenticateWithCredential(this.auth.currentUser, credential)
  }

  getIdToken = async () => {
    if (!this.auth.currentUser) return
    return getIdToken(this.auth.currentUser, false)
  }

  deleteUser = async () => {
    if (!this.auth.currentUser) return
    try {
      await deleteUser(this.auth.currentUser)
    } catch (error: any) {
      throw error
    }
  }
}

export default FirebaseAuth
