import { useState, useEffect } from 'react'
import { User } from '@firebase/auth'
import { FirebaseAuth } from 'shared/services'
import {
  LoadingStatus,
  ReauthenticationStatus,
  ReauthenticationVariant,
} from 'shared/types'
import { useAuthContext } from 'shared/components'
import { useHistory } from 'react-router'
import { Errors, REAUTHENTICATION_STATUS_QUERY_PARAM, paths } from 'config'
import jwt, { JwtPayload } from 'jsonwebtoken'

interface DecodedFirebaseToken extends JwtPayload {
  claims: {
    app_user_id: string
  }
}

const auth = new FirebaseAuth()

export const useAuth = () => {
  const user = useAuthContext()
  const [loading, setLoading] = useState(LoadingStatus.Idle)
  const [error, setError] = useState<string | null>(null)
  const history = useHistory()

  const onReauthenticateSuccess = (reauthVariant: ReauthenticationVariant) => {
    const path =
      reauthVariant === ReauthenticationVariant.CHANGE_PASSWORD
        ? paths.changePassword
        : paths.deleteAccount
    history.push(
      `${path}?${REAUTHENTICATION_STATUS_QUERY_PARAM}=${ReauthenticationStatus.SUCCESS}`
    )
  }

  const signIn = async (
    email: string,
    password: string,
    reauthenticate: boolean,
    reauthVariant: ReauthenticationVariant | null
  ) => {
    try {
      setLoading(LoadingStatus.Pending)
      if (reauthenticate && reauthVariant !== null) {
        await auth.reauthenticateWithPasswordCredential(email, password)
        onReauthenticateSuccess(reauthVariant)
      } else if (reauthVariant !== null) {
        await auth.signIn(email, password)
        onReauthenticateSuccess(reauthVariant)
      } else {
        await auth.signIn(email, password)
      }
      setLoading(LoadingStatus.Succeeded)
      return
    } catch (error: any) {
      setError(error?.code || null)
      setLoading(LoadingStatus.Failed)
    }
  }

  const signOut = () => auth.signOut()

  const isAuthenticated = !!user.user && user.user.emailVerified

  return {
    user,
    isAuthenticated,
    loading,
    error,
    signIn,
    signOut,
  }
}

export const useSignInWithLink = () => {
  const [loading, setLoading] = useState(LoadingStatus.Idle)
  const [error, setError] = useState<string | null>(null)
  const history = useHistory()

  const onReauthenticateSuccess = (reauthVariant: number) => {
    const path =
      reauthVariant === ReauthenticationVariant.CHANGE_PASSWORD
        ? paths.changePassword
        : paths.deleteAccount
    history.push(
      `${path}?${REAUTHENTICATION_STATUS_QUERY_PARAM}=${ReauthenticationStatus.SUCCESS}`
    )
  }

  const signInWithLink = async (
    email: string,
    emailLink: string,
    reauthenticate: boolean,
    reauthVariant: ReauthenticationVariant | null
  ) => {
    try {
      setLoading(LoadingStatus.Pending)
      if (reauthenticate && reauthVariant !== null) {
        await auth.reauthenticateWithEmailLinkCredential(email, emailLink)
        onReauthenticateSuccess(reauthVariant)
      } else if (reauthVariant !== null) {
        await auth.signInWithEmailLink(email, emailLink)
        onReauthenticateSuccess(reauthVariant)
      } else {
        await auth.signInWithEmailLink(email, emailLink)
        await auth.reloadUser()
      }
      window.localStorage.removeItem('emailForSignIn')
      setLoading(LoadingStatus.Succeeded)
      return
    } catch (error: any) {
      setError(error?.code || null)
      setLoading(LoadingStatus.Failed)
    }
  }

  return {
    loading,
    error,
    signInWithLink,
  }
}

export const useConfirmPasswordReset = () => {
  const [loading, setLoading] = useState(LoadingStatus.Idle)
  const [error, setError] = useState<string | null>(null)

  const confirmPasswordReset = async (code: string, password: string) => {
    try {
      setLoading(LoadingStatus.Pending)
      await auth.confirmPasswordReset(code, password)
      setLoading(LoadingStatus.Succeeded)
      setError(null)
      return
    } catch (error: any) {
      setError(error?.code || null)
      setLoading(LoadingStatus.Failed)
    }
  }

  return {
    loading,
    error,
    confirmPasswordReset,
  }
}

export const useChangePassword = () => {
  const [loading, setLoading] = useState(LoadingStatus.Idle)
  const [error, setError] = useState<string | null>(null)

  const changePassword = async (
    password: string,
    onRequiresRecentLoginError: () => void
  ) => {
    try {
      setLoading(LoadingStatus.Pending)
      await auth.updatePassword(password)
      setLoading(LoadingStatus.Succeeded)
      setError(null)
    } catch (error: any) {
      if (error?.code === Errors.RequiresRecentLogin) {
        onRequiresRecentLoginError()
      }
      setError(error?.code || null)
      setLoading(LoadingStatus.Failed)
    }
  }

  return {
    loading,
    error,
    changePassword,
  }
}

export const useVerifyPasswordResetCode = () => {
  const [loading, setLoading] = useState(LoadingStatus.Idle)
  const [error, setError] = useState<string | null>(null)

  const verifyPasswordResetCode = async (code: string) => {
    try {
      setLoading(LoadingStatus.Pending)
      await auth.verifyPasswordResetCode(code)
      setLoading(LoadingStatus.Succeeded)
      setError(null)
      return
    } catch (error: any) {
      setError(error?.code || null)
      setLoading(LoadingStatus.Failed)
    }
  }

  return {
    loading,
    error,
    verifyPasswordResetCode,
  }
}

export const useSendPasswordResetEmail = () => {
  const [loading, setLoading] = useState(LoadingStatus.Idle)
  const [error, setError] = useState<string | null>(null)

  const sendPasswordResetEmail = async (email: string) => {
    try {
      setLoading(LoadingStatus.Pending)
      await auth.sendPasswordResetEmail(email)
      setLoading(LoadingStatus.Succeeded)
      setError(null)
      return
    } catch (error: any) {
      setError(error?.code || null)
      setLoading(LoadingStatus.Failed)
    }
  }

  return {
    loading,
    error,
    sendPasswordResetEmail,
  }
}

export const useAccountActivate = () => {
  const [loading, setLoading] = useState(LoadingStatus.Idle)
  const [error, setError] = useState<string | null>(null)

  const activateAccount = async (code: string) => {
    try {
      setLoading(LoadingStatus.Pending)
      await auth.activateAccount(code)
      setLoading(LoadingStatus.Succeeded)
      return
    } catch (error: any) {
      setError(error?.code || null)
      setLoading(LoadingStatus.Failed)
    }
  }

  return {
    loading,
    error,
    activateAccount,
  }
}

export const useCurrentUser = () => {
  const [user, setUser] = useState<User | null>(null)
  const [userId, setUserId] = useState<string | null>(null)
  const [loading, setLoading] = useState(LoadingStatus.Idle)
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    const fetchAndDecodeToken = async () => {
      if (user) {
        let token = await user.getIdToken()
        let decodedToken = jwt.decode(token) as DecodedFirebaseToken

        if (!decodedToken?.claims?.app_user_id) {
          token = await user.getIdToken(true)
          decodedToken = jwt.decode(token) as DecodedFirebaseToken
        }

        if (decodedToken?.claims?.app_user_id) {
          setUserId(decodedToken.claims.app_user_id)
        }
      }
    }
    fetchAndDecodeToken()
  }, [user])

  useEffect(() => {
    setLoading(LoadingStatus.Pending)
    const unsubscribe = auth.onAuthStateChanged(authUser => {
      setUser(authUser)
      setLoading(LoadingStatus.Succeeded)
    })

    return () => {
      unsubscribe()
      setUser(null)
      setUserId(null)
      setError(null)
      setLoading(LoadingStatus.Idle)
    }
  }, [])

  return { user, loading, error, auth, userId }
}

export const useFetchSignInMethodsForEmail = () => {
  const [loading, setLoading] = useState(LoadingStatus.Idle)
  const [error, setError] = useState<string | null>(null)

  const fetchSignInMethodsForEmail = async (email: string) => {
    try {
      setLoading(LoadingStatus.Pending)
      const data = await auth.fetchSignInMethodsForEmail(email)
      // eslint-disable-next-line no-throw-literal
      if (data.length === 0) throw { code: 'auth-email-not-found' }
      setLoading(LoadingStatus.Succeeded)
      return data
    } catch (error: any) {
      setError(error?.code || null)
      setLoading(LoadingStatus.Failed)
    }
  }

  return {
    loading,
    error,
    fetchSignInMethodsForEmail,
  }
}

export const useSendSignInLinkToEmail = () => {
  const [loading, setLoading] = useState(LoadingStatus.Idle)
  const [error, setError] = useState<string | null>(null)

  const sendSignInLinkToEmail = async (
    email: string,
    reauthenticate: boolean,
    reauthVariant: ReauthenticationVariant | null
  ) => {
    try {
      setLoading(LoadingStatus.Pending)
      if (reauthenticate && reauthVariant !== null) {
        if (reauthVariant === ReauthenticationVariant.CHANGE_PASSWORD) {
          await auth.sendSignInLinkToEmailBeforPasswordChange(email)
        } else {
          await auth.sendSignInLinkToEmailBeforDeleteAccount(email)
        }
      } else {
        await auth.sendSignInLinkToEmail(email)
      }
      window.localStorage.setItem('emailForSignIn', email)
      setLoading(LoadingStatus.Succeeded)
      setError(null)
      return
    } catch (error: any) {
      setError(error?.code || null)
      setLoading(LoadingStatus.Failed)
    }
  }

  return {
    loading,
    error,
    sendSignInLinkToEmail,
  }
}

export const useIsSignInWithEmailLinkCheck = () => {
  const isSignInWithEmailLinkCheck = (emailLink: string) => {
    return auth.isSignInWithEmailLink(emailLink)
  }
  return { isSignInWithEmailLinkCheck }
}

export const useDeleteUser = () => {
  const [loading, setLoading] = useState(LoadingStatus.Idle)
  const [error, setError] = useState<string | null>(null)

  const deleteUser = async () => {
    try {
      setLoading(LoadingStatus.Pending)
      await auth.deleteUser()
      setLoading(LoadingStatus.Succeeded)
      setError(null)
    } catch (err: any) {
      setError(err?.code || null)
      setLoading(LoadingStatus.Failed)
      throw err?.code || null
    }
  }

  return {
    loading,
    error,
    deleteUser,
  }
}
